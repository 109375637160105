.subheader { position: relative; width: 100%; z-index: 0; max-width: 1300px; margin: 0 auto; height: 20px;
		
	ul.breadcrumbs { list-style: none; margin: 0px; padding: 0; float: left;
		li { margin-right: 5px; padding-right: 20px; display: inline-block; background: url('/assets/images/icons/breadcrumb.png') no-repeat; background-size: 12px 12px; background-position: 100% 55%; 
			a { color: $primary-color; font-size: 15px; }
		}
		li:last-child { background-image: none; }
	}

	p.disclosure { float: right; margin: 4px 0 0 0px; font-size: 14px; }

}

.article { text-align: left; margin: 0 auto; max-width: 650px;
	
		h1.title { text-align: center; margin:40px auto 20px auto; }
		img.cover-photo { max-width: 100%; width: 100%; border-radius: 3px; }
		
		p:first-of-type { font-size: 22px; }
		p.disclosure { background: $light-border; display: inline-block; border-radius: 5px; margin: 0 0 20px 0; padding: 5px 15px; font-size: 14px; }
		.published { padding-bottom: 15px; margin: 20px 0; 
			p { color: $primary-color; margin: 0; line-height: 140%; height: 35px; 
				span { display: inline-block; font-size: 14px; font-weight: 400; margin: 6px 12px 0 0; float: left; }
				img { border-radius: 100%; max-width: 40px; float: left; margin: 0 20px; }
				a { color: $primary-color; text-decoration: underline; }
			}
		}


	.gift { padding: 10px 0; margin: 10px auto 30px auto; max-width: 693px; display: block; position: relative; text-align:center;
		h2 { font-size: 30px; margin: 0px 0 20px 0; 
			a { color: $primary-color; }
			span { background: $yellow; text-align: center; margin-right: 10px; border-radius: 100%; line-height: 175%; width: 41px; height: 41px; display: inline-block; }
		}
		h3 { margin: 20px 0; font-size: 19px; font-weight: 300; font-family: "Gotham SSm A", "Gotham SSm B"; }
		a.cta { background: $red; color: #fff; font-size: 15px; font-weight: 600; margin: 30px 0; padding: 10px 25px; border-radius: 10px; }
		.image { width: 100%; max-width: 100%; border-radius: 3px; margin-top: 30px; }			
		p { font-size: 18px; text-align:left;  }
	}

	.insight { clear: both; max-width: 700px; width: 100%; margin: 80px auto 0px auto; 
		h2 { text-align: center; }
	}
	
	.youtube { margin: 20px 0; 
		h2 { margin-bottom: 10px; }
		a.youtube-video { position: relative; display: block;  transition: opacity 0.3s; -moz-transition: opacity 0.3s; -webkit-transition: opacity 0.3s;
			img.thumb { width: 100%; display: block; margin: 0; border-radius: 2px; border: none !important; box-shadow: none !important; -webkit-box-shadow: none !important; -moz-box-shadow: none !important; background: linear-gradient(180deg, rgba(0,0,0,0) 44%, rgba(0,0,0,1) 100%); }
			img.icon { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 80px !important; margin: 0 !important; border: none !important; box-shadow: none !important; -webkit-box-shadow: none !important; -moz-box-shadow: none !important; }
		}
		a.youtube-video:hover { opacity: 0.8; }
	}
	.video-container iframe { height: 386.5px; width: 100%; border: none; }
	
	
}

@media screen and (max-width:840px) {

	.subheader { height: auto;
		ul.breadcrumbs { display: none; }
		p.disclosure { float: none; text-align: center; width: 90%; margin: 0 auto 15px auto; }
	}
	
	.article { width: 90%; margin: 5%;
		
		h1.title { text-align: center; margin: 20px 0;  }
		p:first-of-type { font-size: 17px; }
						
	}

}