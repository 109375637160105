.labs {
	
	ul.list-labs { list-style: none; margin: 80px auto; padding: 0; max-width: 900px;text-align:left;
		li { position: relative; margin-bottom: 40px;
			a { display: block; padding: 2.5%; position: relative; padding-left: 320px; 
				h3 { margin: 0; }
				img { float: left; position: absolute; top: 10%; left: 0; max-width: 280px; }
			}
			a:hover h2 { color: $blue; }
		}
	}
	
}

.guess-who-nba { text-align: center; background:#fff; max-width: 1100px; margin: 40px auto 0 auto;

	h1 { font-size: 80px; line-height: 85%; margin: 60px 0 40px 0;  font-family: proxima-nova, sans-serif;
		span { display: block; font-size: 28px; 
			strong { margin: 0 20px; }
		}
	}
	
	img { width: 100%;}
	
	hr { width: 100%; max-width: 150px; margin: 70px auto; height: 1px; border: none; color: #ddd; }

	h2 { margin: 0; font-size: 42px; }
	p { margin: 10px 0 40px;  }
	a.cta { display: inline-block; padding: 30px 40px; background: $blue; color:#fff; font-weight: 500; border-radius: 10px; }

}

.tp { text-align: center; 
	
	.splash { padding: 70px 0; margin: 0 0 50px 0; background: #26201c; background-image: radial-gradient(80% 80% at center, #26201c, #000); 
		.inner { width: 900px; margin: 0 auto; text-align: center; 
			h1 { margin: 0 0 40px 0; font-size: 74px; color: #fff; 
				span { text-transform: uppercase; font-size: 30px; display: block; line-height: 150%; }
			}
		}
	}

	h1 { font-size: 78px; margin: 50px 0 10px 0; }
	.tp-image { margin-top:20px; }
	
	hr { width: 100%; max-width: 150px; margin: 70px auto; height: 1px; border: none; color: #ddd; }

	h3 { margin: 10px 0 20px; font-size: 36px; font-weight: 700; line-height: 185%; 
		a { border-bottom: 1px solid $blue; color: $primary-color; text-decoration: none; }
		span { font-weight: 800; margin: 0 5px; }
		span.mobile-dot { display: none; }
	}

	.products { width: 890px; margin: 0 auto; text-align: center;
		.product { border-radius: 4px; float: left; padding: 25px 50px; margin: 20px; width: 300px; -webkit-box-shadow: 0 0 50px #ddd; -moz-box-shadow: 0 0 50px #ddd; box-shadow: 0 0 50px #ddd;
			h2 { margin: 20px 0; font-size: 16px; text-transform: uppercase; color: $primary-color; }
			img { width:100%;}
			ul { list-style: none; margin: 0 0; padding: 0; 
				li { margin: 20px 0; line-height: 185%; font-size: 15px; 
					strong { display: block; margin-bottom: 15px; line-height: 145%; font-size: 24px; color: $primary-color; }
					a { border-bottom: 1px solid $blue; color: $primary-color; }
				}
			}
			a.cta { clear: both; font-size: 17px; background: #fff; font-weight: 600; cursor: pointer;  display: inline-block; margin: 35px 0 25px 0; padding: 20px 30px; border: 2px solid $blue; color: $blue; font-weight: 600; border-radius: 10px; 
				span { margin: 0 5px; }
			}
		}
	}
}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

	.labs {
	
		ul.list-labs li a { padding-left: 0px; text-align: center;
			img { float: none; position: relative; top: auto; left: auto !important; max-width: 280px; }
		}
	
	}

	.tp { 
		.splash { padding: 70px 0; margin: 0;
			.inner { width: 90%; padding: 0 5%; 
				h1 { font-size: 50px; 
					span { font-size: 20px; }
				}
			}
			iframe { width: 100%; height: auto; }
		}
		
		h3 { margin: 30px 5%; width: 90%; line-height: 150%; font-size: 18px; 
			span.mobile-dot { display: inline-block; }
			br { display: none; }
		}

		.products { width: 90%; margin: 0 5%;
			.product { margin: 30px 00px; float: none; padding: 5%; width: 90%; 		}
		}
	}

	.guess-who-nba { width: 90%; margin: 0 5%;
		h1 { font-size: 60px;margin: 0px 0 40px 0;
			span { font-size: 15px; margin-bottom: 10px; 
				strong { margin: 0 10px; }
			}
		}
		h2 { font-size: 30px; }
		p { font-size: 16px; }
		a.cta { padding: 5% 8%;  font-size: 14px; }
		hr { margin: 40px auto; }
		img { width: 100%; } 

	}
		
}