.homepage { 
	
	h1 { max-width: 800px; margin: 60px auto 120px auto; text-align: center; }
	
	.article-list { display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 50px;
		.item {
			h2 { text-align: center; color: $primary-color; font-size: 21px; font-weight: 900; margin: 10px 0; 
				a { color: #000; }
			}
			img { width: 100%; }
		}
	}
	
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

	.homepage {
		
		.article-list { grid-template-columns: 1fr 1fr;
		}
		
	}

}