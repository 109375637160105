.footer { margin: 110px 0 0 0; padding: 0; 
	
	.wrapper { background: url('../images/mark.png') no-repeat; background-size: 40px; background-position: 50%; padding: 50px 0 50px 0; }
	
	p { text-align: right; margin-top: 50px; font-weight: 800;
		span { margin: 0 5px; }
		a { color: #000; }
	}
	a.top { display: inline-block; color: $primary-color; border-bottom: 1px solid #ddd; padding-bottom: 1px; float: left; font-weight: 800; margin-top:57px; }
	
	.footer-pages { 
		span:last-child { display: none; }
	}
	
	
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

	.footer { 

		.wrapper { background-image: none; }

		p { text-align: center; margin-top: 50px;
			a { display: inline-block; text-align: center; margin-top: 5px; float: none; }
		}
	
		ul { text-align: center; 
			li { width: 100%; display: block; float: none; margin-bottom: 50px; }
		}

	}

}