$primary-color: #333;
$secondary-color: #777;
$red: #D50000;
$yellow: #f6c100; 
$blue: #2962FF; 
$light-blue: #F2F6FF;
$border: #CFD8DC;
$light-border: #ECEFF1;
$background-color: #FAF5EF;

a { text-decoration: none; color: $blue; }
.clear { clear: both; }

h1 { font-size: 65px; font-family: "Chronicle Display A", "Chronicle Display B"; color: $primary-color; }
h2 { font-size: 36px; font-family: "Chronicle Display A", "Chronicle Display B"; color: $primary-color; }
h3 { font-size: 30px; color: $primary-color; font-family: "Chronicle Display A", "Chronicle Display B";  }
h4 { font-size: 17px; font-weight: 600; text-transform: uppercase; }

p,ul,ol { line-height: 180%; font-size: 17px; color: $primary-color; }

// Forms 
.field { display: block; text-align: center;
	label { display: block; margin-bottom: 30px; text-align: left;
		input,textarea { display: block; width: 96%; padding: 2%; border: 1px solid #ddd; margin-top: 4px; font-size: 17px; }
	}
	button { background: $yellow; padding: 3% 10%; color: #fff; font-size: 18px; border: none; cursor: pointer; border-radius: 20px; }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

	h1 { font-size: 48px; }
	p.intro { font-size: 22px; }

}