.header { 
	.inner { max-width: 1300px; margin: 0 auto; padding: 10px 0; text-align: left; display: grid;  grid-template-columns: 1fr 4fr; align-items: center; 
		h1 { font-size: 18px; font-weight: 800; margin: 20px 0; text-indent: -9999em; display: inline-block; position: relative; height: 31.5px; width: 126px;
			a { color: $primary-color; display: block; z-index: 10000; height: 39px; width: 155px; background: url('../images/logo-red.png') no-repeat; background-size: 126px 31.5px; }
		}
		.mobile-menu-wrapper { justify-self: end;
			nav { 
				a { color: $primary-color; font-weight: 600; font-size: 15px; padding: 5px 0; margin-left: 20px; text-transform: uppercase; }
				ul { list-style: none; margin: 0; padding: 0; 
					li { position: relative; display: inline-block; 
						ul { display: none; list-style: none; margin: 0; padding: 0; position: absolute; width: 200px; top: 40px; left: 0px; background: #fff; border-radius: 4px; z-index: 10000;
							li { float: none; display: block; border-bottom: 1px solid $background-color; height: auto; text-align: left;
								a { display: block; margin-right: 10px; padding: 15px 0px; font-size: 14px; text-transform: none; font-weight: 400; line-height: 18px; }
								a:hover { color: $red; }
								hr { margin: 20px; }
							}
							li.current-page a { color: $red; font-weight: 600; }
							li:last-child { border-bottom: none; }
						}
						ul.open { display: block; }
					}
				}
			}			
		}

		#toggle-mobile-menu { display: none; }
	}
}

@media screen and (max-width:840px) {

	.header { float: none; width: 100%; display: block; text-align: left; 
		.inner { display: block;
			h1 { margin-left: 5%; }
			
			.mobile-menu-wrapper { display: block; display: none; background:#fff;
				nav { position: relative; top: auto; right: auto; 
					ul li { display: block; 
						ul { display: block; position: relative; top: auto; left: auto; display: none; width: 100%;
							li { }
						}
					}
				}
				nav.pages a { margin-left: 0; display: inline; }
				nav.categories a { margin-right: 0; padding-right: 0;  }
				a { display: block; }
			}
		
			#toggle-mobile-menu { position: absolute; top: 25px; right:5%; width: 20px; height: 20px; padding: 10px; display: inline-block; background: url('../images/icons/mobile-menu.png') no-repeat; background-size: 20px 20px; background-position: 50%; text-indent: -9999em;  }
		}
		p.sidebar-tip { display: none; }	
	}

}