body { text-align: center; font-family: "Gotham SSm A", "Gotham SSm B"; background: $background-color; margin: 0; padding: 0; } 

body.no-sidebar { background: #fff; }

.wrapper { margin: 0 auto; width: 100%; max-width: 1050px; text-align: left; 

}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

	.wrapper { width: 90%; margin: 5%;

	}

}